<template>
  <v-container fluid>
      <v-row>
          <v-col>
            <v-dialog v-model="dialog" width="700" persistent fullscreen transition="dialog-bottom-transition" >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class=" blue--text mb-2 accent-2" outlined v-bind="attrs" v-on="on" @click="getSuppliersReference(); getItemsReference()"><v-icon>mdi-plus</v-icon> زیادکردنی کڕین</v-btn>
            </template>
            <v-card>
                <v-toolbar dark outlined :class="isUpdate?'orange accent-2':'blue accent-2'" elevation="1" >
                <v-btn icon @click="dialog = false" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn  text @click="isUpdate? update():create()" >
                    <v-icon>{{isUpdate? 'mdi-check':'mdi-plus'}}</v-icon> {{isUpdate? 'نوێکردنەوەی کڕین': 'زیادکردنی کڕین'}} </v-btn>
                </v-toolbar-items>
                </v-toolbar>
                <v-form ref="purchase">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" md="2" lg="2" sm="12">
                                <v-select :items="[{name: 'کڕین', value: 'p'},{name: 'گەڕانەوە', value: 'rp'}]" item-text="name" item-value="value" dense label="جۆری وەسڵ" outlined v-model="purchase.purchase_type" type="number"></v-select>
                                <v-select :items="[{name: 'قەرز', value: 'd'},{name: 'کاش', value: 'c'}]" item-text="name" item-value="value" dense label="جۆری پارەدان" outlined v-model="purchase.payment_type" type="number"></v-select>
                            </v-col>
                            <v-col cols="12" md="2" lg="2" sm="12">
                               <v-autocomplete :items="supplierReference" item-text="supplier_name" item-value="supplier_id" dense outlined label="ناوی کۆمپانیا" v-model="purchase.supplier_id" :rules="rules.companyRule"></v-autocomplete>
                                <v-text-field @focus="$event.target.select()"  dense label="ژمارەی وەصل" outlined v-model="purchase.no" type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2" lg="2" sm="12">
                                <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field @focus="$event.target.select()" v-model="purchase.date" label="بەروار" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined dense ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="purchase.date" @input="menu1 = false" ></v-date-picker>
                                </v-menu>
                                <v-text-field @focus="$event.target.select()" append-icon="mdi-currency-usd"  dense label="کرێی گواستنەوە" outlined v-model="purchase.delivery_cost" type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2" lg="2" sm="12">
                                <v-text-field @focus="$event.target.select()" @blur="generatePrice()" append-icon="mdi-percent"  dense label="داشکاندن" outlined v-model="purchase.discount" type="number"></v-text-field>
                                <v-textarea  label="تێبینی" rows="1" auto-grow outlined dense v-model="purchase.note"></v-textarea>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="12" md="2" lg="2" sm="12">
                                <v-text-field @focus="$event.target.select()" success id="success" dense readonly label="سەرجەم" outlined :value="'$'+total"></v-text-field>
                            </v-col>
                        </v-row>
                        <hr>
                        <br>
                        <v-row>
                            <v-col>
                                <v-data-table  class="elevation-2 rounded-lg"  :item-class="itemRowBackground" :headers="secondHeaders" :items="purchase.purchase_items" hide-default-footer :items-per-page="-1">
                                    <template v-slot:[`item.total`]="{ item }">
                                        {{'$'+ ((item.cost_price* item.qty_unit * item.item_per_unit) + (item.qty_item * item.cost_price)).toFixed(2)}}
                                    </template>
                                    <template v-slot:[`item.total_item`]="{ item }">
                                        {{Number(item.qty_unit*item.item_per_unit) + Number(item.qty_item)}}
                                    </template>
                                    <template v-slot:[`item.item_name`]="item">
                                        <v-edit-dialog
                                        >
                                        {{ item.item.item_name }}
                                        <template v-slot:input>
                                            
                                            <v-autocomplete :disabled="item.item.delete" class="my-5" :items="itemReference" label="ناوی کاڵا" outlined dense item-text="item_name" item-value="item_id" v-model="item.item_id" return-object @change="selectItem($event, item.item, true)"></v-autocomplete>
                                        </template>
                                        </v-edit-dialog>
                                    </template>
                                    <template v-slot:[`item.cost_price`]="item">
                                        <v-edit-dialog
                                        >
                                        {{ '$'+item.item.cost_price }}
                                        <template v-slot:input>
                                            <v-text-field @focus="$event.target.select()" :disabled="item.item.delete" class="my-5" :rules="rules.numberRule" label="نرخی کڕینی دانە" v-model="item.item.cost_price" type="number"></v-text-field>
                                        </template>
                                        </v-edit-dialog>
                                    </template>
                                    <template v-slot:[`item.item_price`]="item">
                                        <v-edit-dialog
                                        >
                                        {{ '$'+item.item.item_price }}
                                        <template v-slot:input>
                                            <v-text-field @focus="$event.target.select()" :disabled="item.item.delete" :rules="rules.numberRule" class="my-5" label=" نرخی فرۆشتنی دانە" v-model="item.item.item_price" type="number"></v-text-field>
                                        </template>
                                        </v-edit-dialog>
                                    </template>
                                    <template v-slot:[`item.qty_unit`]="item">
                                        <v-edit-dialog
                                        >
                                        {{ item.item.qty_unit }}
                                        <template v-slot:input>
                                            <v-text-field @focus="$event.target.select()" :disabled="item.item.delete"  class="my-5" :label="'بڕ بە (' + item.item.unit_name + ')'" v-model="item.item.qty_unit" type="number"></v-text-field>
                                        </template>
                                        </v-edit-dialog>
                                    </template>
                                    <template v-slot:[`item.qty_item`]="item">
                                        <v-edit-dialog
                                        >
                                        {{ item.item.qty_item }}
                                        <template v-slot:input>
                                            <v-text-field @focus="$event.target.select()" :disabled="item.item.delete"  class="my-5" :label="'بڕ بە (دانە)'" v-model="item.item.qty_item" type="number"></v-text-field>
                                        </template>
                                        </v-edit-dialog>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon" >
                                            <v-icon large class="ml-2" v-if="hover" :color="item.delete?'white accent-2':'red accent-2'" id="icon" @click="removeItemToggle(item)">{{item.delete? 'mdi-restore':'mdi-delete'}}</v-icon>
                                            <v-icon large class="ml-2" v-else >{{item.delete? 'mdi-restore':'mdi-delete'}}</v-icon>
                                        </v-hover>
                                    </template>
                                </v-data-table>
                                <v-autocomplete class="mt-5" :filter="filter"  :items="itemReference" label="ناوی کاڵا" outlined dense item-text="item_name" item-value="item_id" v-model="purchase_item" return-object @change="selectItem($event, purchase_item)"></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
            </v-dialog>
            <v-card>
                <v-data-table class="elevation-2 rounded-md" :headers="headers" :items="purchases" hide-default-footer  :items-per-page="20"  :loading="loading">
                    <template v-slot:top>
                        <v-text-field @focus="$event.target.select()"
                        v-model="table.search"
                        label="گەڕان"
                        class="mx-4"
                        ></v-text-field>
                    </template>
                    <template v-slot:[`item.total`]="{ item }">
                        {{'$' + item.total}}
                    </template>
                    <template v-slot:[`item.discount`]="{ item }">
                        {{'%' + item.discount}}
                    </template>
                    <template v-slot:[`item.delivery_cost`]="{ item }">
                        {{'$' + item.delivery_cost}}
                    </template>
                    <template v-slot:[`item.payment_type`]="{ item }">
                        {{item.payment_type == 'd'? 'قەرز':'کاش'}}
                    </template>
                    <template v-slot:[`item.purchase_type`]="{ item }">
                        {{item.purchase_type == 'p'? 'کڕین':'گەڕانەوە'}}
                    </template>
                    
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-hover v-slot="{ hover }" open-delay="75" close-delay="75" id="icon" >
                        <v-icon large class="ml-2" v-if="hover" color="blue accent-2" id="icon" @click="prepairEdit(item.purchase_id)">mdi-pencil</v-icon>
                        <v-icon large class="ml-2" v-else>mdi-pencil</v-icon>
                        </v-hover>
                        <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon" >
                            <v-icon large class="ml-2" v-if="hover" color="red accent-2" id="icon" @click="remove(item.purchase_id)">mdi-delete</v-icon>
                            <v-icon large class="ml-2" v-else >mdi-delete</v-icon>
                        </v-hover>
                    </template>
                </v-data-table>
            </v-card>
            <v-pagination color="orange accent-2" :length="Math.ceil(table.numberOfPurchases / 20)"  v-model="table.page"></v-pagination>
          </v-col>
      </v-row>
      <notification/>
  </v-container>
</template>

<script>
export default {
    data() {
        return {
            table: {
                page: 1,
                numberOfPurchases: 0,
                search: ''
            },
            items: [],
            dialog: false,
            menu1: false,
            supplierReference: [],
            itemReference: [],
            item_id: 0,
            purchase: {
                purchase_id: 0,
                supplier_id: 0,
                no: 0,
                total: 0,
                date: new Date().toISOString().split("T")[0],
                note: '',
                payment_type: 'd',
                purchase_type: 'p',
                discount: 0,
                delivery_cost: 0,
                purchase_items: []
            },
            loading: false,
            purchases: [],
            purchase_item:{
                cost_price:0,
                item_price: 0,
                item_name: '',
                qty_unit:0,
                qty_item: 0,
                item_id: 0,
            },
            headers: [
                {text: 'ژمارەی وەصل', value: 'purchase_id', align: 'center'},
                {text: 'ناوی کۆمپانیا', value: 'supplier_name', align: 'center'},
                {text: 'بەروار', value: 'date', align: 'center'},
                {text: 'جۆری کڕین', value: 'payment_type', align: 'center'},
                {text: 'جۆری وەسڵ', value: 'purchase_type', align: 'center'},
                {text: 'داشکاندن', value: 'discount', align: 'center'},
                {text: 'کرێی گواستنەوە', value: 'delivery_cost', align: 'center'},
                {text: 'کۆی گشتی', value: 'total', align: 'center'},
                {text: 'تێبینی', value: 'note', align: 'center'},
                {text: 'کردارەکان', value: 'actions', align: 'center'},
            ],
            secondHeaders: [
                {text: 'ناوی کاڵا', value: 'item_name', align: 'center'},
                {text: 'نرخی کڕینی دانە', value: 'cost_price', align: 'center'},
                {text: 'نرخی فرۆشتنی دانە', value: 'item_price', align: 'center'},
                {text: 'بڕ بە (یەکە)', value: 'qty_unit', align: 'center'},
                {text: 'بڕ بە (دانە)', value: 'qty_item', align: 'center'},
                {text: 'کۆی دانە', value: 'total_item', align: 'center'},
                {text: 'بڕی دانە لە یەکەدا', value: 'item_per_unit', align: 'center'},
                {text: 'کۆی گشتی', value: 'total', align: 'center'},
                {text: 'کردارەکان', value: 'actions', align: 'center'},
            ],
            rules: {
                stringRule: [
                    (v) => {
                        return ( v != '' || 'تکایە ئەم خانەیە پڕبکەرەوە')
                    }
                ],
                numberRule: [
                    (v) => {
                        return ( v > 0 || 'ژمارەکە پێویستە لە 0 زیاتربێت')
                    }
                ],
                companyRule: [
                    (v) => {
                        return ( v > 0 || 'تکایە کۆمپانیا دیاریبکە')
                    }
                ],
                
            }
        }
    },
    methods: {
        async getPurchases(){
            try {
                this.loading = true
                const {data} = await this.axios.get('/purchase', {params:{page:this.table.page, search: this.table.search}})
                data.purchases.forEach(purchase => {
                    purchase.date = new Date(purchase.date).toISOString().split("T")[0]
                });
                this.purchases = data.purchases
                this.table.numberOfPurchases = data.count
                this.loading = false
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
            }
        },
        async getSuppliersReference(){
            try {
                const {data} = await this.axios.get('/supplier/forReference')
                this.supplierReference = data
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
            }
        },
        async getItemsReference(){
            try {
                const {data} = await this.axios.get('/item/forReference')
                this.itemReference = data
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
            }
        },
        filter (item, queryText, itemText) {
            return item.item_code.includes(queryText) ||
            item.item_name.includes(queryText)
        },
        async create(){
            if (this.$refs.purchase.validate()) {
                if (this.purchase.purchase_items.length > 0) {
                    const purchase = {...this.purchase}
                    purchase.purchase_items.forEach( purchase_item=> {
                        
                    })
                    this.removeProperty(purchase, ['supplier_name'])
                    purchase.total = this.total
                    purchase.purchase_items.forEach(purchase_item => {
                        purchase_item.qty = Number(purchase_item.qty_unit * purchase_item.item_per_unit)+ Number(purchase_item.qty_item)
                        purchase_item = this.removeProperty(purchase_item, ['item_name', 'item_code',  'item_per_unit', 'unit_name', 'qty_item', 'qty_unit', 'item_price_wholesale', 'item_code'])
                    })
                    try {
                        
                        await this.axios.post('/purchase', purchase)
                        this.reset()
                        this.getPurchases()
                        this.dialog = false
                        this.emitter.$emit('notify', {type: 'success', text:'ووەصڵەکە زیادکرا'})
                    } catch (error) {
                        if (error.response.status == 422) {
                            return this.emitter.$emit('notify', {type: 'error', text:`تکایە هەموو بەشەکان بە تەواوی پڕبکەروە!`})
                        }
                        this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
                    }
                }else{
                    this.emitter.$emit('notify', {type: 'error', text:'هیچ کاڵایەک زیاد نەکراوە'})
                }
                
            }
        },
        async update() {
            if (this.$refs.purchase.validate()) {
                try {
                    const purchase = {...this.purchase}
                    this.removeProperty(purchase, ['supplier_name'])
                    purchase.total = this.total
                    purchase.purchase_items.forEach(purchase_item => {
                        purchase_item.qty = Number(purchase_item.qty_unit * purchase_item.item_per_unit)+ Number(purchase_item.qty_item)
                        purchase_item = this.removeProperty(purchase_item, ['item_name', 'item_code',  'item_per_unit', 'unit_name', 'qty_item', 'qty_unit', 'item_price_wholesale', 'item_code'])
                    })
                    await this.axios.patch('/purchase/'+ this.purchase.purchase_id, purchase)
                    this.getPurchases()
                    this.reset()
                    this.dialog = false
                    this.emitter.$emit('notify', {type: 'success', text:'وەصڵەکە نوێکرایەوە'})

                } catch (error) {
                    if (error.response.status == 422) {
                        return this.emitter.$emit('notify', {type: 'error', text:`${error.response.data.field} is duplicate`})
                    }
                    this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
                }
            }
        },
        remove(id){
            this.$confirm('ئایا دڵنیاییت لە سڕینەوەی ئەم وەصڵە؟', 'دڵنیابوونەوە', 'question').then(async () => {
                try {
                    await this.axios.delete('/purchase/'+id)
                    this.getPurchases()
                } catch (error) {
                    this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
                }
            })
        },
        reset(){
            this.purchase = {
                purchase_id: 0,
                supplier_id: 0,
                no: 0,
                total: 0,
                date: new Date().toISOString().split("T")[0],
                note: '',
                payment_type: 'd',
                purchase_type: 'p',
                discount: 0,
                delivery_cost: 0,
                purchase_items: []
            }
            this.$refs.purchase.resetValidation();
        },
        removeProperty(obj = {}, fields){
            for (let i = 0; i < fields.length; i++)  delete obj[fields[i]]
        },
        async prepairEdit(id){ 
            this.getSuppliersReference()
            this.getItemsReference()
             try {
                const {data} = await this.axios.get('/purchase/'+id)
                data.date = new Date(data.date).toISOString().split("T")[0]
                data.purchase_items.forEach(purchase_item => {
                    purchase_item.delete = false
                    purchase_item.qty_unit = Math.floor(purchase_item.qty / purchase_item.item_per_unit)
                    purchase_item.qty_item = purchase_item.qty % purchase_item.item_per_unit
                })
                this.purchase = data
                this.purchase.current_purchase_type = this.purchase.purchase_type
                this.dialog = true
             } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
             }
        },
        async selectItem(val, obj, inList=false){
            
            if (inList) {
                obj.item_name = val.item_name
                obj.unit_name = val.unit_name
                obj.item_name = val.item_per_unit
                obj.item_id = val.item_id
                obj.cost_price = val.cost_price
                obj.item_price = val.item_price
                
            }else{
                const [p_item] = this.purchase.purchase_items.filter(purchase_item => purchase_item.item_id == val.item_id)
                
                if (!p_item) this.purchase.purchase_items.push({...val,qty_unit:0,qty_item:0, item_per_unit: val.item_per_unit, unit_name: val.unit_name})  
                
                this.purchase_item ={}
            }
        },
        itemRowBackground: function (item) {
            return item.delete  ? 'red accent-2' : ''
        },
        removeItemToggle(item){
        if (item.purchase_item_id) {
            if (item.delete) {
                item.delete = false
            }else{
                this.$confirm('ئایا دڵنیاییت لە سڕینەوەی ئەم کاڵایە؟', 'دڵنیابوونەوە', 'question').then(async () => {
                item.delete = true
                })
            }
            
        }else{
            this.purchase.purchase_items = this.purchase.purchase_items.filter(p_item => p_item.item_id != item.item_id)
        }
    },
    async generatePrice(){
        if (this.purchase.discount > 0) {
            
            this.purchase.purchase_items.forEach(item => {
                item.cost_price = item.cost_price - (item.cost_price * (this.purchase.discount /100))
            })
        }
    }
    },
    
    created() {
        this.getPurchases()
    },
    watch: {
        dialog(val){
            if (!val) {
                this.supplierReference = []
                this.itemReference = []
                this.reset()
            }
        },
        'table.search': function () {
            this.getPurchases()
        },
        'table.page': function () {
            this.getPurchases()
        }
    },
    computed: {
        isUpdate(){
            return this.purchase.purchase_id
        },
        total(){
            let total = 0
            this.purchase.purchase_items.forEach(purchase_item => total+= (purchase_item.qty_unit * purchase_item.cost_price * purchase_item.item_per_unit) +(purchase_item.qty_item * purchase_item.cost_price))
            return (total - this.purchase.delivery_cost).toFixed(2)
        }
    }
}
</script>

<style>
    #success{
    color: #4caf50 !important;
  }
  tr td {
      border-left: 0.1PX rgba(0, 0, 0, 0.38) solid;
  }
  

  
</style>